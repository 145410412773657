// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { Row, Col } from 'antd/lib/grid';

function LoginWithKeycloakPageComponent(): JSX.Element {
    const sizes = {
        xs: { span: 14 },
        sm: { span: 14 },
        md: { span: 10 },
        lg: { span: 4 },
        xl: { span: 4 },
    };

    return (
        <>
            <Row justify='center' align='middle'>
                <Col {...sizes}>
                    <Title level={2}> Labelstreet </Title>
                    <Title level={4}> Computer Vision Annotation Tool (CVAT) </Title>
                    <Link to='/accounts/keycloak_custom/login/' className='ant-btn ant-btn-primary login-form-button'>Login mit MSI Audi</Link>
                </Col>
            </Row>
        </>
    );
}

export default withRouter(LoginWithKeycloakPageComponent);
